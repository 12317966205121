
import React from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
const Contact = () => {
  return (
    <section className='lg:section justify-center max-h-[350px] mb-32 w-full flex' id='contact'>
      <div className='text-left mx-20 border-t-2 border-t-accent w-full' >

          {/* text */}
          <motion.div
            variants={fadeIn('up', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='grid md:grid-cols-3 pt-6 grid-cols-1 pl-3 gap-y-0'
          > 
          <div  className='flex flex-col'>
          <h3 className='h3 mb-0 '> Call </h3>
            <a  className='hover:scale-110 delay-50 transition-all hover:text-accent' href="tel:415-316-4593"> +1(415)316-4593 </a>
          </div>
          <div className='flex flex-col'>
          <h3 className='h3 mb-0 cursor-auto'> Email </h3>
          <a  className='hover:scale-110 delay-50 transition-transform hover:text-accent' href="mailto:ahawamda@ucsc.edu"> ahawamda@ucsc.edu </a>
          <a  className='hover:scale-110 delay-50 transition-transform hover:text-accent' href="mailto:ahmad@utopianseries.com"> ahmad@utopianseries.com </a>
          <a  className='hover:scale-110 delay-50 transition-transform hover:text-accent' href="mailto:ahmadhawamdah@yahoo.com"> ahmadhawamdah@yahoo.com </a>
          </div>

    
          <div className="flex gap-x-8 text-3xl h-8 mt-4 md:mt-0 justify-center">
            <a href="https://www.linkedin.com/in/ahmad-hawamdah-587b47105/" className="hover:scale-110 delay-50 transition-all hover:text-accent">
              <FaLinkedin />
            </a>
            <a href="https://github.com/ahmadhawamdah"  className="hover:scale-110 delay-50 hover:text-accent transition-all ">
              <FaGithub />
            </a>
      
          </div>

         
          </motion.div>
     

      </div>
    </section>
  );
};

export default Contact;
