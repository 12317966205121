import React from 'react';
// countup
import CountUp from 'react-countup';
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';

import { Link } from 'react-scroll';
const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section' id='about' ref={ref}>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-10 lg:flex-row  lg:gap-x-20 lg:gap-y-0'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='hidden md:flex flex-1 bg-about bg-contain bg-no-repeat h-[640px] mix-blend-lighten bg-top'
          ></motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='h2 text-accent'>About me.</h2>
            <h3 className='h3 mb-4'>
              I'm a Technology &amp; People Enthusiast.
            </h3>
            <p className='mb-6'>
            With expertise in bootstrapping and building projects from scratch, I excel in empowering, delegating, and leading software development teams to deliver top-quality results. I am also skilled in maintaining, testing, improving, and scaling agile projects achieving sustainable growth.
            </p>
            {/* stats */}
            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[35px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={6} duration={2} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of <br />
                  Experience
                </div>
              </div>
              <div>
                <div className='text-[35px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={30} duration={3} /> : null}
                  %
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Project Efficiency <br />
                  Increase
                </div>
              </div>
              <div>
                <div className='h-100 text-[35px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={90} duration={3} /> : null}
                  k+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Active <br />
                  Users
                </div>
              </div>
            </div>
            <div className='flex gap-x-8 items-center'>
            <Link to ="contact"  spy = {true} smooth = {true} >
              <button className='btn btn-lg'> 
            Contact  </button>
            </Link>
              <Link to='work' spy = {true} smooth = {true} className='text-gradient btn-link cursor-pointer'>
                Portfolio
              </Link>
            </div>
            
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
