import React from "react";
// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";
// img
import Img1 from "../assets/portfolio-img1.png";
import Img2 from "../assets/portfolio-img2.png";
import Img3 from "../assets/portfolio-img3.png";

const Work = () => {
  return (
    <section className="section" id="work">
      <div className="container mx-auto select-none">
        <div className="flex flex-col lg:flex-row gap-x-10">
          <motion.div
            variants={fadeIn("right", 0.3)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0"
          >
            {/* text */}
            <div className="mb-[-20px]">
              <h2 className="h2 leading-tight text-accent">
                Technologies <br />
              </h2>
              <div className="max-w-sm">
                <h3 className="h3">
                  {" "}
                  Frontend ⚛️
                  <p className="font-secondary leading-tight font-medium text-base">
                    {" "}
                    React.js, TypeScript, Next.js, Framer Motion, Three.js, Tailwind, SASS, &amp; MUI
                  </p>
                </h3>

                <h3 className="h3">
                  {" "}
                  Backend 🌐
                  <p className="font-secondary leading-tight font-medium text-base">
                    MongoDB, Node.js, Express.js, PostgresSQL, Firebase, Sanity
                    io, &amp; GraphQL
                  </p>
                </h3>
                <h3 className="h3">
                  {" "}
                  UX/UI 🖥️
                  <p className="font-secondary leading-tight font-medium text-base">
                    {" "}
                    Figma, Adobe, &amp; CapCut
                  </p>
                </h3>
              </div>
            </div>
            {/* image */}
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              {/* overlay */}
              <a href="https://www.plots.events/">
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img1}
                  alt=""
                />
                {/* pretitle */}
                <div className="absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">What's Plots?</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 pr-5 md:pr-0 group-hover:bottom-10 md:group-hover:bottom-16 transition-all duration-700 z-50">
                  <span className=" text-white">
                    React, TypeScript, Tailwind, SASS, AWS, GraphQL
                  </span>
                </div>
              </a>
            </div>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.3 }}
            className="flex-1 flex flex-col gap-y-10"
          >
            {/* image */}
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              {/* overlay */}
              <a href="https://www.utopianseries.com/">
                <div className="group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}
                <img
                  className="group-hover:scale-125 transition-all duration-500"
                  src={Img2}
                  alt=""
                />
                {/* pretitle */}
                <div className="absolute -bottom-full left-12 padd group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">Utopian Series</span>
                </div>
                {/* title */}
                <div className="absolute -bottom-full left-12 pr-5 md:pr-0 group-hover:bottom-10 md:group-hover:bottom-16 transition-all duration-700 z-50">
                  <span className=" text-white">
                    React, TypeScript, Next 14, Framer Motion, Three.js, Sanity io
                  </span>
                </div>
              </a>
            </div>
            {/* image */}
            <div className="group relative overflow-hidden border-2 border-white/50 rounded-xl">
              {/* overlay */}
              <a href="https://www.ghardghome.com/">
                <div className=" group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300"></div>
                {/* img */}

                <img
                  className=" group-hover:scale-125 transition-all duration-500"
                  src={Img3}
                  alt=""
                />
                {/* pretitle */}
                <div className="cursor-pointer absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50">
                  <span className="text-gradient">GH Entertainment</span>
                </div>
                {/* title */}
                <div className="cursor-pointer absolute -bottom-full left-12 pr-5 md:pr-0 group-hover:bottom-10 md:group-hover:bottom-16 transition-all duration-700 z-50">
                  <span className=" text-white">
                    React, TypeScript, Next 13, Tailwind, Sanity io
                  </span>
                </div>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
